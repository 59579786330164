import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { CollectionData, DocumentData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Item from '~/components/List/Item/Schema/Collection';
import CMSView from '~/components/View';
import List from '~/containers/Schema/List';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollection: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  params: { collectionId },
  user,
}) => {
  const [collection, setCollection] = useState<DocumentType<CollectionType>>();
  const [model, setModel] = useState<any>();

  useEffect(() => {
    let isMounted = true;
    const collectionData = new CollectionData({
      espaceId: espace.id,
    });

    collectionData
      .findById(collectionId)
      .then((snapshot: DocumentType<CollectionType>) => {
        if (isMounted) {
          setCollection(snapshot);
          const newModel = new DocumentData({
            ...snapshot,
            espaceId: espace.id,
            parentCollectionName: 'collections',
            parentId: collectionId,
            queryBy: snapshot.queryByFields?.join(','),
          });

          setModel(newModel);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [collectionId]);

  if (!model || !collection) {
    return null;
  }

  const handleResetOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    await model.resetIndex();
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espace.id}/collections/${collectionId}/documents/`}
          model={model}
          search={location.search}
          title={collection.label}
          user={user}
        />
        <div className="flex items-center justify-start -mt-9 mx-6 md:mx-0">
          <Button
            color="neutral"
            size="sm"
            text="Editer la collection"
            to={`/espaces/${espace.id}/collections/${collectionId}/update`}
            variant="ghost"
          />
          <Button
            color="neutral"
            onClick={handleResetOnClick}
            size="sm"
            text="Synchroniser l'index de recherche"
            variant="ghost"
          />
        </div>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollection);
